import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import arrowLeft from '../components/svg/arrowLeft.svg'
import arrowRight from '../components/svg/arrowRight.svg'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { StackerSlide, StackerSlider } from "react-stacker";

class BlogRoll extends React.Component {

  state = {
    value: 0,
    width: '250px',
    height: '450px',
    w: 500

  }

  componentDidMount(){
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if(w > 960){
      this.setState({width: '400px', height: '550px'})
      this.setState({w: w})
    }
  }


onPrevChangeHandler = () => {
  const { data } = this.props
    const { value } = this.state
    const { edges: posts } = data.allMarkdownRemark
    const lastItem = posts.length - 1
  if (value === 0 ) {
  this.setState({ value: lastItem })
  } else {
    this.setState({ value: value - 1})
  }
};

onNextChangeHandler = () => {
  const { data } = this.props
    const { value } = this.state
    const { edges: posts } = data.allMarkdownRemark
  if (value + 1 >= posts.length) {
  this.setState({value: 0 })
  } else {
    this.setState({value: value + 1})
  }
};


  render() {
    const { data } = this.props
    const { value, w } = this.state
    const { edges: posts } = data.allMarkdownRemark

    return (
      <>
      { w < 960 ? null: <div className="column is-2"/>}
      <div className="column is-6">
        <article className="description"><h2>{posts && posts[value].node.frontmatter.title}</h2>
        <div className="flex flexColumn">
        <p className="articleDescription">{posts && posts[value].node.excerpt}</p>
        <Link type="button" className="readMore" to={posts && posts[value].node.fields.slug}>Czytaj więcej</Link>
        </div>
        </article>
        <div className="mobileSpacer" /> 
      </div>
      <div className="column is-4 flex flexColumn">
      <StackerSlider
               ref={slider => (this.slider = slider)}
               dots={false}
               slideWidth="300px"
               slideHeight="450px"
               infiniteLoop={true}
               onPrevChange={this.onPrevChangeHandler}
               onNextChange={this.onNextChangeHandler}
            >
        {posts &&
          posts.map(({ node: post }) => (
            <StackerSlide key={post.id}>
                  {post.frontmatter.featuredimage ? (
                    <article className="stackSlider">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `Zdjęcie główne od ${
                            post.title
                          }`
                        }}
                        
                      />
                      </article>
                  ) : null}
            </StackerSlide>
          ))}
      </StackerSlider>
      <div className="spacer" />
      <div className="flex flexRow paddingTop20">
      <div className="sliderButton" onClick={() => { this.slider.updateSlidesPosition(-1); this.onPrevChangeHandler()}}><img src={arrowLeft} alt='zmień artukuł' /></div>
      <div className="stackPagination">{`${value + 1} z ${posts.length}`}</div>
      <div className="sliderButton" onClick={() => { this.slider.updateSlidesPosition(1); this.onNextChangeHandler()}}><img src={arrowRight} alt='zmień artukuł' /></div>
      
      </div>
      </div>
      </>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 100) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
